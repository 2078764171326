import { ActivatedRoute, Router } from '@angular/router';
import { CardItemService, DashbordDefs } from '../services/dashbord-card-item.service';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CardItem } from '../models/CardItem.model';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {

  type: DashbordDefs = null;

  componentDestroyed$: Subject<boolean> = new Subject()

  rowHeight: 200;
  colWidth: 200;

  settings = {
    rowhight: 200,
    numberOfCols: 4
  };

  update: Subject<HttpParams> = new Subject<HttpParams>();

  injector: Injector;

  title: string = "";
  cards: CardItem<any>[] = [];

  constructor(
    public cardService: CardItemService,
    public activeRoute: ActivatedRoute,
    public router: Router) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  ngOnInit(): void {



    this.activeRoute.params.subscribe(val => {

      this.cards = [];

      const id = val['id'] ? +val['id'] : DashbordDefs.Generic;


      console.log('id', id)

      this.cards = [];
      this.type = id;
      this.title = "";

      this.cardService.getCardItems(id).subscribe(def => {
        if (!def) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.cards = def.cards;
        this.title = def.titel;

      });

    });
  }

  onUpdate(data: any) {
    this.update.next(data)
  }
}
