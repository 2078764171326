<h1>
  {{"GluePlanPrint.Beam_Material"| translate }}
</h1>

<button kendoButton themeColor="info" type="button" size="large" [svgIcon]="printIcon" (click)="onClick(pdfs)"></button>

<kendo-pdf-export #pdfs class="font-size-large" [scale]="0.35" [autoPrint]="true">
  <div *ngFor="let beamMaterial of beamMaterials">
    <div *ngFor="let printlabel of beamMaterials.constructor(beamMaterial.noOfLabels);" class="page-break borderbox">
      <div class="row pad0">
        <div class="k-card-body">
          <app-qr-code-generator [qrData]="{ gsId: beamMaterial.gluesetId, bmId: beamMaterial.beamMaterialId }"
            [showInputValues]="true">
          </app-qr-code-generator>
        </div>
        <div class="padding-right wordwrap">
          <div *ngFor="let group of beamMaterial.groupedDemandDetails">
            <dl>
              <dt>{{ group.orderId }}</dt>
              <div *ngFor="let demandDetail of group.demandDetails; let j = index">
                <dd><span class="bullet">• </span>{{ getNonNullValuesOfDemand(demandDetail) }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class="label">
        <kendo-label>
          <span>{{ beamMaterial.width}} cm x {{ beamMaterial.height}} cm x {{ beamMaterial.length}} m</span>
        </kendo-label>
      </div>
      <div class="margin-top">
        <div class="label">
          <kendo-label>{{beamMaterial.trimmingLocation}}</kendo-label>
        </div>
        <div class="label">
          <kendo-label>{{beamMaterial.productionDate}}</kendo-label>
        </div>
        <div class="label">
          <kendo-label> {{ getNonNullValuesOfBeamMaterial(beamMaterial) }}</kendo-label>
        </div>
      </div>
      <div class="margin-top">
        <div class="label">
          <kendo-label>{{beamMaterial.orderComment}}</kendo-label>
        </div>
        <div class="label">
          <kendo-label>{{beamMaterial.customerDescription | translate}}</kendo-label>
        </div>
      </div>
      <div class="row margin-top">
        <div class="col">
          <kendo-label
            class="font-size-larger"><b>{{beamMaterial.layerNumber}}/{{beamMaterial.beamNumber}}</b></kendo-label>
        </div>
        <div class="col">
          <div class="row">
            <kendo-label *ngIf="beamMaterial.isTakeTestPsc" translate>Operative.TestPiece</kendo-label>
          </div>
          <div class="row">
            <kendo-label>{{beamMaterial.beamMaterialType | translate}}</kendo-label>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-pdf-export>
