import { Demandline } from './demand-line.model';
import { Guid } from '@app/core/models/Guid';
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';
import { ITransportPackageAllocation } from '@app/core/services/http-services/gluelam/demand.service';
import { ProductionStateGroupCode } from '@app/core/models/production-state-group-code-enum';
import { StateCategory } from '@app/core/models/state-category-enum';

export class BeamMaterialLine {
  public IsDataCut: boolean = false;
  public IsDataPasted: boolean = false;

  constructor(
    public transportPackageDemand: Demandline,
    public length: number,
    public beamOrderRowID: number,
    public NumberOfBeams: number,
    public Cut: number,
    public IndexInCut: number,
    public affectedByTemplate: IBeamMaterialType,
    public guid: string = null,
    public transportPackageId: number,
    public BeamMaterialLineID: number,
    public BeamMaterialID: number,
    public transportPackageAllocation: ITransportPackageAllocation,
    public ProductionStateCode?: string,
    public ProductionStateGroupCode?: number,
    public ProductionStateId?: number,
    public ProductionStateName?: string
  ) {
    if (this.guid == null) {
      this.guid = Guid.newGuid();
    }
  }

  public getColor(defaultNoOfBeams, colorLight: string = null): any {
    const color = defaultNoOfBeams > 1 ? 'lightgray' : colorLight;
    return { fill: color, 'stroke-width': 1, stroke: 'rgb(0,0,0)' };
  }

  public getLongText(): string {
    return (
      this.transportPackageDemand.customerDesc +
      ' - ' +
      this.transportPackageDemand.OrderText +
      ' - ' +
      this.transportPackageDemand.BeamText
    );
  }

  public getTooltip(selectedLaminaDimensionWidth): string {
    return (
      this.transportPackageDemand.customerDesc +
      ' \n' +
      this.transportPackageDemand.orderID + ':' + this.transportPackageDemand.orderRowID +
      ' \n' +
      this.transportPackageDemand.width + 'x' + this.transportPackageDemand.hight + 'x' + this.transportPackageDemand.length +
      ' / ' +
      selectedLaminaDimensionWidth + 'x' + (this.transportPackageDemand.DefaultLamminaPlaneThickness * this.transportPackageDemand.NumberOfLaminas) +
      ' \n' +
      this.transportPackageDemand.ConstructionCertification +
      ' ' +
      this.transportPackageDemand.DefaultLamminaPlaneThickness + 'mm' + ' ' +
      ' \n' +
      this.getStateText()
    );
  }

  public getStateText() {
    if (this.ProductionStateGroupCode === ProductionStateGroupCode.NOTSTARTED) {
      return StateCategory.NOTSTARTED;
    } else if (this.ProductionStateGroupCode === ProductionStateGroupCode.STARTED) {
      return StateCategory.STARTED;
    } else if (this.ProductionStateGroupCode === ProductionStateGroupCode.DONE) {
      return StateCategory.DONE;
    } else if (this.ProductionStateGroupCode === ProductionStateGroupCode.REJECTED) {
      return StateCategory.REJECTED;
    }
  }
}
