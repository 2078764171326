
export enum BeamMaterialType {
  UNKNOWN,
  NORMAL,
  SPECIAL_FORM,
  ANGELD_CUT,
  WIDTH_SPLIT,
  ANGLED_HEIGHT_SPLIT,
  ANGELD_HEIGHT_SLICE,
  KVH
}
