import { Observable, Subject } from 'rxjs';

import { Type } from '@angular/core';

export class CardItem<T> {
  constructor(
    public component: Type<IDashTileMember<T>>,
    public title:string,
    public cols:number,
    public rows:number,
    public col?:number,
    public row?:number,
    public data?:any
  ) {}
}

export interface IDashTileMember<T> {
  subscribeUpdate(update: Subject<T>)
  loadingCallback():Subject<boolean>
  errorCallback():Subject<boolean>
  data:T
}

export interface IGltDashFilterData {
  start:Date;
  end:Date;
}
