import { Component, OnDestroy } from '@angular/core';
import { IDashTileMember, IGltDashFilterData } from '../models/CardItem.model';
import { Subject, Subscription } from 'rxjs';

import { IProdOrderStateResponseDTO } from '@app/core/models/analytics/plan-events';
import { IntlService } from '@progress/kendo-angular-intl';
import { OperativeAnalyticsService } from '@app/core/services/http-services/analytics/operative-analytics.service';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-glt-kpi',
  template: `
  <kendo-chart *ngIf="dataAnalytics.length > 0">
    <kendo-chart-series>
      <kendo-chart-series-item
        type="pie"
        [data]="dataAnalytics"
        field="value"
        [labels]="{ visible: true, content: labelContent }">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
    `,
  styleUrls: ['../dash.scss']
})

export class OperativeProdOrdersPerStateComponent<T extends IGltDashFilterData> implements IDashTileMember<T>, OnDestroy {
  showTitle: boolean = false;
  value:number = 0;

  uom:string = "PSC";

  private update: Subscription;
  private loading: Subject<boolean> = new Subject<boolean>();
  private hasError: Subject<boolean> = new Subject<boolean>();


  public dataAnalytics:{ category:string, value:number }[] = null;

  constructor(private operativeAnalyticsService:OperativeAnalyticsService, private intl: IntlService ) {
    this.labelContent = this.labelContent.bind(this);
  }

  public data: any;

  subscribeUpdate(update: Subject<IGltDashFilterData>) {
    this.update = update.subscribe(d => {
      this.onupdate(d)
    })
  }

  loadingCallback(): Subject<boolean> {
    return this.loading;
  }

  errorCallback(): Subject<boolean> {
    return this.hasError;
  }

  onupdate(data:IGltDashFilterData) {
    this.loading.next(true);

    this.dataAnalytics = [];

      this.operativeAnalyticsService.prodOrdersPerState(data).subscribe(data => {

        let total = data.preview + data.planned + data.released + data.started + data.stopped + data.completed;

        let g:{ category:string, value:number }[] = [];
        g.push({ category: 'preview', value: data.preview });
        g.push({ category: 'planned', value: data.planned});

        g.push({ category: 'released', value: data.released});

        g.push({ category: 'started', value: data.started});

        g.push({ category: 'stopped', value: data.stopped});

        g.push({ category: 'planned', value: data.reported});

        g.push({ category: 'completed', value: data.completed});
        // this.dataAnalytics.push({ category: 'closed', value: data.closed });

        console.log(g);

        this.dataAnalytics = g;

        this.loading.next(false);
      });
  }
  ngOnDestroy(): void {
    this.update.unsubscribe();
  }

  public labelContent(args: SeriesLabelsContentArgs): string {
    return `${args.dataItem.category} : ${this.intl.formatNumber(
      args.dataItem.value,
      "n"
    )} PSC`;
  }
}
