import { Component, OnDestroy } from '@angular/core';
import { IDashTileMember, IGltDashFilterData } from '../models/CardItem.model';
import { Subject, Subscription } from 'rxjs';

import { GLTAnalyticsService } from '@app/core/services/http-services/analytics/glt-analytics.service';
import { IBeamStateResponseDTO } from '@app/core/models/analytics/plan-events';

@Component({
  selector: 'app-glt-beams-produced',
  template: `
    <kendo-chart style="height: 320px">
        <kendo-chart-series>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.GENERATED' | translate }}" [data]="events.items"
          field="generated"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.Started' | translate }}" [data]="events.items"
          field="started"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.done' | translate }}" [data]="events.items"
          field="done"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="line" [stack]="false" name="{{ 'GluePlan.rejected' | translate }}" [data]="events.items"
          field="rejected"
          categoryField="date">
          </kendo-chart-series-item>
</kendo-chart-series>
      </kendo-chart>
  `,
  styles: [
  ]
})

export class GltEventsBeamsComponent<T extends IGltDashFilterData> implements IDashTileMember<T>, OnDestroy {

  data: any;

  events: IBeamStateResponseDTO;

  private update: Subscription;
  private loading: Subject<boolean> = new Subject<boolean>();
  private hasError: Subject<boolean> = new Subject<boolean>();

  constructor(private gltKpiService: GLTAnalyticsService) { }

  ngOnDestroy(): void {
    this.update.unsubscribe();
  }
  subscribeUpdate(update: Subject<IGltDashFilterData>) {
    this.loading.next(true)
    this.update = update.subscribe(d => {
       this.gltKpiService.beamEvents(d).subscribe(f => {
        this.events = f;
       // this.loading.next(false)
      })
    });
  }

  loadingCallback(): Subject<boolean> {
    return this.loading;
  }
  errorCallback(): Subject<boolean> {
    return this.hasError;
  }


}
