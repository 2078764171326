import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { GltSearch } from '@app/core/models/forms/analytics/glt-search-form';

@Component({
  selector: 'app-form-glt-dashboard',
  template: `
<form
    [formGroup]="form"
    (ngSubmit)="load()"
    class="row"
    style="margin-bottom: 10px;"
>
    <kendo-formfield appearance="fill">
        <kendo-label translate>App.StartDate</kendo-label>
        <kendo-datepicker formControlName="start"></kendo-datepicker>
    </kendo-formfield>
    <kendo-formfield appearance="fill">
        <kendo-label translate>App.EndDate</kendo-label>
        <kendo-datepicker formControlName="end"></kendo-datepicker>
    </kendo-formfield>
    <div style="display: flex; align-items: flex-end;">
      <button size="large" kendoButton themeColor="primary">Search</button>
    </div>
</form>
  `,
  styles: [
  ]
})
export class FormGltDashboardComponent implements AfterContentInit {


  defaultEnd: Date = new Date('2024-03-01')
  defaultDayRange: number = 14


  @Output() criteria = new EventEmitter<any>();

  form: FormGroup = new FormGroup<GltSearch>({
    start: new FormControl<Date>(this.addDays(this.defaultEnd, this.defaultDayRange * -1)),
    end: new FormControl<Date>(this.defaultEnd)
  });


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  load() {
    this.criteria.emit(this.form.value);
  }

  ngAfterContentInit(): void {
    this.load();
  }
}
