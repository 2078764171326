
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { BeamIconEnum } from '@app/core/models/demand-line.model';

marker('BeamIconType.Straight');
marker('BeamIconType.Arched');
marker('BeamIconType.FishBelly');
marker('BeamIconType.GableRoof');
marker('BeamIconType.SpecialForm');
marker('BeamIconType.StraightWithElevation');

export  class BeamIconTypeEnumHelper {
  static getBeamIconTypeEnumTranslations(): { key: BeamIconEnum, text: string }[] {
    return [
      { key: BeamIconEnum.Straight, text: 'BeamIconType.Straight' },
      { key: BeamIconEnum.Arched, text: 'BeamIconType.Arched' },
      { key: BeamIconEnum.FishBelly, text: 'BeamIconType.FishBelly' },
      { key: BeamIconEnum.GableRoof, text: 'BeamIconType.GableRoof' },
      { key: BeamIconEnum.SpecialForm, text: 'BeamIconType.SpecialForm' },
      { key: BeamIconEnum.StraightWithElevation, text: 'BeamIconType.StraightWithElevation' },

    ];
  }
}
