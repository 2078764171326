<i data-fa-symbol="edit" class="fas fa-pencil fa-fw"></i>
<svg:rect [attr.width]="widthPX" [attr.height]="hightPX" [ngStyle]="bml.getColor(defaultNoOfBeams, colorLight)" (contextmenu)="grab($event)" >
  <title>{{ bml.getTooltip(selectedLaminaDimensionWidth) }}</title>
</svg:rect>
<svg:text class="small white rotate no-pointer" y="13" x="5" (contextmenu)="grab($event)"
  *ngIf="bml.transportPackageDemand.transportPackageAllocation">
  {{bml.transportPackageAllocation?.packageID.substr(bml.transportPackageAllocation.packageID.length - 2)}}
</svg:text>
<svg:rect [attr.width]="20" [attr.height]="hightPX" [ngStyle]="{fill: bml.transportPackageDemand.Color}"
  (contextmenu)="grab($event)" class="orderMarker no-pointer">
</svg:rect>

<svg:text class="small no-pointer" y="13" x="25" *ngIf="this.widthPX > withDisplayBreakPoint">
  <tspan x="25" y="13">
    {{bml.transportPackageDemand?.width}}x{{bml.transportPackageDemand?.hight}}x{{bml.transportPackageDemand?.length}} /
      {{selectedLaminaDimensionWidth}}x{{planedThickness * bml.transportPackageDemand?.NumberOfLaminas | number }}
  </tspan>
  <tspan  x="25" y="25">
    {{ planedThickness }} mm /<tspan [style.fill]="getStateColor(bml.ProductionStateGroupCode)"> {{ bml.getStateText() }}</tspan>
  </tspan>

</svg:text>
<!-- Medium text -->
<svg:text class="mini no-pointer" y="13" x="25" *ngIf="this.widthPX < withDisplayBreakPoint && hightPX > 34">
  <tspan x="25" y="13">
    {{bml.transportPackageDemand?.width}}x{{bml.transportPackageDemand?.hight}}x{{bml.transportPackageDemand?.length}}
  </tspan>
  <tspan  x="25" y="23">
    {{ planedThickness }} mm
  </tspan>
  <tspan  x="25" y="35" [style.fill]="getStateColor(bml.ProductionStateGroupCode)">
    {{ bml.getStateText() }}
  </tspan>

</svg:text>
<!-- Short text -->
<svg:text class="mini no-pointer" y="13" x="25" *ngIf="this.widthPX < withDisplayBreakPoint && hightPX < 34">
  <tspan x="25" y="13">
    {{bml.transportPackageDemand?.width}}x{{bml.transportPackageDemand?.hight}}x{{bml.transportPackageDemand?.length}}
  </tspan>
  <tspan  x="25" y="23">
    {{ planedThickness }} mm / <tspan [style.fill]="getStateColor(bml.ProductionStateGroupCode)"> {{ bml.getStateText() }}</tspan>
  </tspan>
</svg:text>
<svg:text *ngIf="bml.transportPackageDemand.HasTemplate === false && bml.affectedByTemplate" x="100" y="23" class="no-pointer"
  style="font-family: FontAwesome">
</svg:text>

  <svg>
    <g>
      <foreignObject [attr.x]="widthPX - 70" y="0" [attr.width]="50" [attr.height]="50">
          <app-beam-icon [beamIconId]="bml.transportPackageDemand?.beamIconId"></app-beam-icon>
      </foreignObject>
    </g>
  </svg>
