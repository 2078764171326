import { IBeamStateResponseDTO, IKPIResponseDTO, IgluePlanStateResponseDTO } from '@app/core/models/analytics/plan-events';

import { AnalyticsHelper } from './analytics-helper';
import { HttpClient } from '@angular/common/http';
import { IGltDashFilterData } from '@app/modules/dashboard/models/CardItem.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GLTAnalyticsService {

  constructor(private http: HttpClient) {}

  public getNummberOfBeams(data:IGltDashFilterData):Observable<IKPIResponseDTO>{
    return this.http.get<IKPIResponseDTO>(`${environment.apiUrl}/AnayticsGLT/kpi/numberOfBeams`, { params: AnalyticsHelper.mapHttpParam(data) })
  }

  public getNummberOfPlans(data:IGltDashFilterData):Observable<IKPIResponseDTO>{
    return this.http.get<IKPIResponseDTO>(`${environment.apiUrl}/AnayticsGLT/kpi/numberOfPlans`, { params: AnalyticsHelper.mapHttpParam(data) })
  }


  public planEvents(data:IGltDashFilterData):Observable<IgluePlanStateResponseDTO>{
    return this.http.get<IgluePlanStateResponseDTO>(`${environment.apiUrl}/AnayticsGLT/planEvents`, { params: AnalyticsHelper.mapHttpParam(data) })
  }

  public beamEvents(data:IGltDashFilterData):Observable<IBeamStateResponseDTO>{
    return this.http.get<IBeamStateResponseDTO>(`${environment.apiUrl}/AnayticsGLT/beamEvents`, { params: AnalyticsHelper.mapHttpParam(data) })
  }
}
