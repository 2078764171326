
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';

marker('BeamMaterialType.UNKNOWN');
marker('BeamMaterialType.NORMAL');
marker('BeamMaterialType.SPECIAL_FORM');
marker('BeamMaterialType.ANGELD_CUT');
marker('BeamMaterialType.WIDTH_SPLIT');
marker('BeamMaterialType.ANGLED_HEIGHT_SPLIT');
marker('BeamMaterialType.ANGELD_HEIGHT_SLICE');
marker('BeamMaterialType.KVH');

export  class BeamMaterialTypeEnumHelper {
  static getBeamMaterialTypeEnumTranslations(): { key: BeamMaterialType, text: string }[] {
    return [
      { key: BeamMaterialType.UNKNOWN, text: 'BeamMaterialType.UNKNOWN' },
      { key: BeamMaterialType.NORMAL, text: 'BeamMaterialType.NORMAL' },
      { key: BeamMaterialType.SPECIAL_FORM, text: 'BeamMaterialType.SPECIAL_FORM' },
      { key: BeamMaterialType.ANGELD_CUT, text: 'BeamMaterialType.ANGELD_CUT' },
      { key: BeamMaterialType.WIDTH_SPLIT, text: 'BeamMaterialType.WIDTH_SPLIT' },
      { key: BeamMaterialType.ANGLED_HEIGHT_SPLIT, text: 'BeamMaterialType.ANGLED_HEIGHT_SPLIT' },
      { key: BeamMaterialType.ANGELD_HEIGHT_SLICE, text: 'BeamMaterialType.ANGELD_HEIGHT_SLICE' },
      { key: BeamMaterialType.KVH, text: 'BeamMaterialType.KVH' },
    ];
  }
}
