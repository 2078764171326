import { Component, OnDestroy } from '@angular/core';
import { IDashTileMember, IGltDashFilterData } from '../models/CardItem.model';
import { Subject, Subscription } from 'rxjs';

import { GLTAnalyticsService } from '@app/core/services/http-services/analytics/glt-analytics.service';

@Component({
  selector: 'app-glt-kpi',
  template: `
    <div class="kpi-container">
      <h1>{{value | number}}</h1>
      <h3>{{uom}}</h3>
    </div>
    `,
  styleUrls: ['./glt-kpi.component.scss','../dash.scss']
})

export class GLTkpiComponent<T extends IGltDashFilterData> implements IDashTileMember<T>, OnDestroy {
  showTitle: boolean = false;
  value:number = 0;

  uom:string = "PSC";

  private update: Subscription;
  private loading: Subject<boolean> = new Subject<boolean>();
  private hasError: Subject<boolean> = new Subject<boolean>();

  constructor(private gltKpiService:GLTAnalyticsService ) {}

  public data: any;

  subscribeUpdate(update: Subject<IGltDashFilterData>) {
    this.update = update.subscribe(d => {
      this.onupdate(d)
    })
  }

  loadingCallback(): Subject<boolean> {
    return this.loading;
  }

  errorCallback(): Subject<boolean> {
    return this.hasError;
  }

  onupdate(data:IGltDashFilterData) {
    this.loading.next(true);

    if(this.data?.kpiId == 'numberOfPlans'){


      this.gltKpiService.getNummberOfPlans(data).subscribe(r => {

        this.value = r.value;


        this.loading.next(false);
      });
    }else{
      this.gltKpiService.getNummberOfBeams(data).subscribe(r => {

        this.value = r.value;


        this.loading.next(false);
      });
    }


    this.value = 0;
    this.uom = "PSC";



  }
  ngOnDestroy(): void {
    this.update.unsubscribe();
  }
}
