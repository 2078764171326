import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export function plannedLamellaWithBoxInfoValidator(formControl: AbstractControl): ValidationErrors | null {
  const formArray = formControl as FormArray;
  const isBlockProduction = formArray.parent?.controls["useBlockProduction"].value;

  const indexToBoxValue = new Map<number, number>();
  const distinctIndices = new Set<number>();
  let isConsistent = true;

  for (const control of formArray.controls) {
    const materialIndex = (control as FormGroup).controls['materialIndex'].value;
    const exchangedMaterialIndex = (control as FormGroup).controls['exchangedMaterialIndex'].value;
    const bufferBox = (control as FormGroup).controls['bufferBox'].value;

  const index = Number(exchangedMaterialIndex ?? materialIndex)

    distinctIndices.add(index);

    if (indexToBoxValue.has(index)) {
      if (indexToBoxValue.get(index) !== bufferBox) {
        isConsistent = false;
        break;
      }
    } else {
      indexToBoxValue.set(index, bufferBox);
    }
  }

  const invalidLamellas = distinctIndices.size > 4;
  const hasInconsistentBoxValues = isBlockProduction && (!isConsistent || new Set(indexToBoxValue.values()).size !== indexToBoxValue.size);

  // Return validation errors if any
  if (invalidLamellas || hasInconsistentBoxValues) {
    return {
      invalidActualLamellas: invalidLamellas,
      invalidBoxValues: hasInconsistentBoxValues,
    };
  }

  return null;
}
