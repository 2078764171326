import { AnalyticsHelper } from './analytics-helper';
import { HttpClient } from '@angular/common/http';
import { IGltDashFilterData } from '@app/modules/dashboard/models/CardItem.model';
import { IProdOrderStateResponseDTO } from '@app/core/models/analytics/plan-events';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OperativeAnalyticsService {

  constructor(private http: HttpClient) {}

  public prodOrdersPerState(data:IGltDashFilterData):Observable<IProdOrderStateResponseDTO>{
    return this.http.get<IProdOrderStateResponseDTO>(`${environment.apiUrl}/AnayticsOperative/prodOrdersPerState`, { params: AnalyticsHelper.mapHttpParam(data) })
  }
}
