import { Component, OnDestroy } from '@angular/core';
import { IDashTileMember, IGltDashFilterData } from '@app-module-dashboard/models/CardItem.model';
import { Subject, Subscription } from 'rxjs';

import { GLTAnalyticsService } from '@app/core/services/http-services/analytics/glt-analytics.service';
import { IgluePlanStateResponseDTO } from '@app/core/models/analytics/plan-events';

@Component({
  selector: 'app-glt-plan-produced',
  template: `
    <kendo-chart style="height: 320px">
        <kendo-chart-series>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.GENERATED' | translate }}" [data]="events.lines"
          field="generated"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.RELEASED' | translate }}" [data]="events.lines"
          field="released"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.SENT_BY_PLANNER' | translate }}" [data]="events.lines"
          field="sentByPlanner"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.STARTED' | translate }}" [data]="events.lines"
          field="started"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.COMPLETED' | translate }}" [data]="events.lines"
          field="completed"
          categoryField="date">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="column" [stack]="true" name="{{ 'GluePlan.CANCELED' | translate }}" [data]="events.lines"
          field="canceled"
          categoryField="date">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
  `,
  styles: [
  ]
})

export class GltEventsPlansComponent<T extends IGltDashFilterData> implements IDashTileMember<T>, OnDestroy {

  data: any;

  events: IgluePlanStateResponseDTO;

  private update: Subscription;
  private readonly loading: Subject<boolean> = new Subject<boolean>();
  private readonly hasError: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly gltKpiService: GLTAnalyticsService) { }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
  subscribeUpdate(update: Subject<IGltDashFilterData>) {
    this.loading.next(true)
    this.update = update.subscribe(d => {
       this.gltKpiService.planEvents(d).subscribe(f => {
        this.events = f;
      })
    });
  }

  loadingCallback(): Subject<boolean> {
    return this.loading;
  }
  errorCallback(): Subject<boolean> {
    return this.hasError;
  }


}
