/* eslint-disable no-unused-vars */

import { CardItem, IGltDashFilterData } from '@app-module-dashboard/models/CardItem.model';
import { Observable, of } from 'rxjs';

import { GLTkpiComponent } from '@app-module-dashboard/glt-kpi/glt-kpi.component';
import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { GltEventsBeamsComponent } from '@app-module-dashboard/glt-events-beams/glt-events-beams.component';
import { GltEventsPlansComponent } from '@app-module-dashboard/glt-events-plans/glt-events-plans.component';
import { OperativeProdOrdersPerStateComponent } from '../operative-prod-orders-per-state/operative-prod-orders-per-state.component';

export enum DashbordDefs {
  Generic = 0,
  Operational = 1,
  Tacktical = 2,
  UserDefined = 3,
  GLT = 4,
  CLT = 5
}

export class DashbordDef<T> {
  public type: DashbordDefs;
  public titel: string;
  public cards: CardItem<T>[];
}

@Injectable()
export class CardItemService {
  getCardItems<T>(type: DashbordDefs): Observable<DashbordDef<any>> {
    switch (type) {
      case DashbordDefs.Operational:
        return of(this.GetGenericDashbord<T>());
      case DashbordDefs.GLT:
        return of(this.GetGltDashbord());
      default:
        throw new Error('No definition found!');
    }
  }
  GetGltDashbord<T extends IGltDashFilterData>(): DashbordDef<T> {
    const def: DashbordDef<T> = {
      titel: 'AppComponent.GLT_Dashbord',
      type: DashbordDefs.GLT,
      cards: [
        new CardItem<T>(GLTkpiComponent<IGltDashFilterData>, 'Dashboard.NumberOfBeams', 1, 1, 1, 1 ,{ kpiId : 'numberOfBeams'}),
        new CardItem<T>(GLTkpiComponent<IGltDashFilterData>, 'Dashboard.NumberOfPlans', 1, 1, 2, 1 ,{ kpiId : 'numberOfPlans'}),
        new CardItem<T>(GltEventsBeamsComponent<IGltDashFilterData>, 'Dashboard.NumberOfBeams', 2, 2, 1, 2),
        new CardItem<T>(GltEventsPlansComponent<IGltDashFilterData>, 'Dashboard.NumberOfPlans', 2, 2, 3, 2),
      ]
    };

    return def;
  }

  GetGenericDashbord<T>(): DashbordDef<T> {
    const def: DashbordDef<T> = {
      titel: 'AppComponent.Operative',
      type: DashbordDefs.Generic,
      cards: [
        new CardItem(OperativeProdOrdersPerStateComponent, 'Dashboard.ProdOrdersPerState', 2, 3, 1, 1),
      ]
    };

    return def;
  }
}

marker([
  'Dashboard.NumberOfBeams',
  'Dashboard.NumberOfPlans',
  'Dashboard.ProdOrdersPerState'
])
