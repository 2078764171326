import { BrowserModule } from '@angular/platform-browser';
import { CardItemService } from './services/dashbord-card-item.service';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { FormGltDashboardComponent } from './form-glt-dashboard/form-glt-dashboard.component';
import { GLTkpiComponent } from './glt-kpi/glt-kpi.component';
import { GltEventsBeamsComponent } from '@app-module-dashboard//glt-events-beams/glt-events-beams.component';
import { GltEventsPlansComponent } from '@app-module-dashboard/glt-events-plans/glt-events-plans.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OperativeKpiComponent } from './operative-kpi/operative-kpi.component';
import { OperativeProdOrdersPerStateComponent } from './operative-prod-orders-per-state/operative-prod-orders-per-state.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TileContentComponent } from './dashboard-layout/tile-content-component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DashboardLayoutComponent,
    GLTkpiComponent,
    TileContentComponent,
    FormGltDashboardComponent,
    OperativeKpiComponent,
    GltEventsBeamsComponent,
    GltEventsPlansComponent,
    OperativeProdOrdersPerStateComponent
  ],
  imports: [
    ReactiveFormsModule,
    DashboardRoutingModule,
    KendoImportModule,
    TranslateModule,
    BrowserModule,
    ChartsModule,
    LayoutModule,
    NgxSpinnerModule,
  ],
  providers: [
    CardItemService
  ],
  bootstrap: [GLTkpiComponent]
})
export class DashboardModule { }
