import { Injectable } from '@angular/core';
import { GluePlanDimension } from '@app/core/models/gluing-plan-pressbed-planning.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { CordHelper } from '@app/modules/gluelam/controls/beam-material-gluset/CordHelper';

@Injectable()
export class PressbedPlanningViewService {
  public containerHeight: number;
  public containerWidth: number;
  public machine: IMachineDTO;
  public gluelamDimensions: GluePlanDimension[];
  public pxPerMMWidth: number = null;
  public pxPerMMHight: number = null;

  private baseHeight = 30;
  private padding = 10;
  private cordHelper: CordHelper;

  public constructor() {}

  public initinitialize(containerHightPX: number, containerWidthPX: number, machine: IMachineDTO) {
    this.containerHeight = containerHightPX;
    this.containerWidth = containerWidthPX;
    this.machine = machine;
    this.setupCoordinateSystem();
  }

  private setupCoordinateSystem() {
    if (this.machine) {
      const mmperPixel = this.containerHeight / this.machine?.maxPressWidth;
      this.cordHelper = new CordHelper(
        this.containerHeight, // height of the coniainer
        this.machine?.maxPressWidth + (mmperPixel * 15), // width of the gluepress machine (hardcoded + padding between the
        this.containerWidth, // width of the coniainer
        this.machine?.maxPressLength ?? 0
      );
      this.pxPerMMHight = this.cordHelper.getHight(1);
      this.pxPerMMWidth = this.cordHelper.getWidth(1);
    }
  }

  getHeight(height: number): number {
    return this.cordHelper.getHight(height);
  }

  getWidth(width: number): number {
    return this.cordHelper.getWidth(width);
  }

  getGluePlanPositions(gluePlan: GluingPlan[], machine: IMachineDTO) {
    this.gluelamDimensions = [];
    gluePlan?.forEach((plan, index) => {
      if (
        (plan.glueSetStateId === GlueSetState.PLANNED || plan.glueSetStateId === GlueSetState.GENERATED) &&
        plan.length > 0
      ) {
        this.machine = machine;
        this.setupCoordinateSystem();
        this.gluelamDimensions.push(this.getDimensions(plan, index));
      }
    });

    return this.calculateY(this.gluelamDimensions);
  }

  private getDimensions(plan: GluingPlan, index: number): GluePlanDimension {
    const dimensions: GluePlanDimension = {
      glueplanId: plan.gluingPlanID,
      name: plan.name,
      date: plan.plannedExecutionStartDate,
      state: GlueSetState[plan.glueSetStateId],
      x: this.cordHelper.getWidth(plan.lenghtOffset - this.machine.defaultCutOffLength),
      y: index * (this.baseHeight + this.padding),
      height: this.baseHeight,
      width: this.cordHelper.getWidth(plan.length),
      beamIconId:plan.beamIconId
    };

    return dimensions;
  }

  private calculateY(dimensions: GluePlanDimension[]) {
    const pressbedMapping: GluePlanDimension[] = [];
    let y = 0;
    for (const dimension of dimensions) {
      let flag = true;
      const filteredPressbed = pressbedMapping.filter((pressbed) => pressbed.y === y);
      for (const plan of filteredPressbed) {
        if (!(plan.x + plan.width <= dimension.x || plan.x >= dimension.x + dimension.width)) {
          flag = false;
          break;
        }
      }

      if (!flag) {
        y += this.baseHeight + 10;
      }

      dimension.y = y;
      pressbedMapping.push(dimension);
    }
    return pressbedMapping;
  }
}
