<h1 translate>{{ title }}</h1>
<app-form-glt-dashboard *ngIf="type == 4 || type == 1 " (criteria)="onUpdate($event)" ></app-form-glt-dashboard>
<kendo-tilelayout
    [columns]="settings.numberOfCols"
    [rowHeight]="settings.rowhight"
    *ngIf="cards.length > 0"
>
    <kendo-tilelayout-item
        *ngFor="let card of cards"
        [col]="card?.col"
        [row]="card?.row"
        [colSpan]="card?.cols"
        [rowSpan]="card?.rows"
        title="{{ card.title | translate }}"
    >
        <kendo-tilelayout-item-body>
            <app-tile-content
                [card]="card"
                [update]="update"
            ></app-tile-content>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>
