import { IDemandDTO, IDemandProperty, ITransportPackageAllocation } from '@app/core/services/http-services/gluelam/demand.service';

import { BeamMaterialLine } from './beam-material-line.model';
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { Guid } from 'guid-typescript';
import { IPlaneProfile } from '@app/core/models/plane-profile';

export class BeamOrderLineCreator {
  static FromJson(lines: any[]): Array<Demandline> {
    return lines.map(
      (obj: IDemandDTO) => BeamOrderLineCreator.FromJsonObj(obj));
  }

  // NOTE: We are using Demandline model in below components
  // 1. 'glulam -> Demand Grid and Save of GlueSet'
  // 2. 'prodOrder detail -> Show Demand Info'
  // 3. 'aot/demand -> To load demand grid'
  // If we are doing any changes in this model make sure you test all components
  static FromJsonObj(obj: IDemandDTO): Demandline {
    return new Demandline(
      obj.demandId,
      obj.orderID,
      obj.orderRowID,
      obj.customerDesc,
      new Date(obj.latestProductionDate),
      obj.width,
      obj.hight,
      obj.length,
      obj.volumeM3,
      obj.planState,
      obj.qty,
      obj.qtyUnit,
      obj.qtyProduced ?? 0,
      obj.numberOfLaminas,
      obj.beamLamminaDimensionID,
      obj.qtyReproduce,
      obj.defaultLamminaPlaneThickness,
      obj.lamminaWidth,
      obj.deafultNumberOfBeamsPerBeamMaterial,
      obj.prodOrderCount,
      obj.consumedBML,
      obj.prodOrderId,
      obj.beamMaterialType,
      obj.color,
      obj.constructionCertification,
      obj.transportPackageId,
      obj.transportPackageCode, // used for grouping and filtering
      new TransportPackageAllocation(
        obj.transportPackageId,
        obj.transportPackageCode,
        obj.transportPackageQty,
        obj.transportPackageQtyReproduce,
        obj.volumeM3
      ),
      obj.externalItemId,
      obj.materialIndex,
      obj.activityIndex,
      obj.materialCode,
      obj.mainProduct,
      new Date(obj.plannedProductionDate),
      new Date(obj.millExitDate),
      obj.visualQuality,
      obj.planeProfileCode,
      obj.planeProfileDesc,
      obj.gluingPlandIds,
      null,
      obj.materialDescription,
      obj.species,
      obj.demandSpecificationsCount,
      obj.siteId,
      null,
      null,
      null,
      false,
      obj.thickness,
      obj.customerNo,
      obj.orderComment,
      obj.orderRowComment,
      obj.pickingInst,
      obj.demandType,
      null,
      obj.isActive,
      null,
      obj.trimmingLocation,
      obj.radius,
      obj.specialFormName,
      obj.beamIconId,
      obj.isSpecialFormBeam,
      obj.siteName,
      obj.planningType,
      obj.rawLamellaThickness,
      obj.planeProfile
    );
  }
}

export class TransportPackageAllocation {
  constructor(
    public transportPackageID: number,
    public packageID: string,
    public qty: number,
    public unitsExtra: number,
    public volumeM3: number
  ) { }
}

/**
 * Represents entity BeamOrderRow
 */
export class Demandline {
  public SelectedForOptimization: boolean;
  public QtySumUI: number;

  public BeamMaterialGlueSetID: number = null;
  public BeamMaterialGlueSetLayer: number = null;

  public get ProductDec(): string {
    return `GL${this.width}${this.hight}`;
  }

  constructor(
    public BeamOrderRowID: number,
    public orderID: string,
    public orderRowID: string,
    public customerDesc: string,
    public latestProductionDate: Date,
    public width: number,
    public hight: number,
    public length: number,
    public volumeM3: number,
    public planState: string,
    public qty: number,
    public qtyUnit: string,
    public QtyPlanned: number,
    public NumberOfLaminas: number,
    public BeamLamminaDimensionID: number,
    public QtyReproduce: number,
    public DefaultLamminaPlaneThickness: number,
    public LamminaPlaneWidth: number,
    public deafultNumberOfBeamsPerBeamMaterial: number,
    public prodOrderCount: number,
    public consumedBML: number,
    public prodOrderId: number,
    public beamMaterialType: IBeamMaterialType,
    public Color: string,
    public ConstructionCertification: string,
    public transportPackageId: number,
    public transportPackageCode: string,
    public transportPackageAllocation: ITransportPackageAllocation,
    public ExternalItemID: string,
    public materialIndex: number,
    public activityIndex: number,
    public materialCode: string,
    public mainProduct: string,
    public plannedProductionDate: Date,
    public millExitDate: Date | null,
    public visualQuality: string,
    public planeProfileCode: string,
    public planeProfileDesc: string,
    public gluingPlandIds: string,
    public material: IEntity,
    public materialDescription: string,
    public species: string,
    public demandSpecificationsCount: number,
    public siteId: Guid,
    public propertys?: IDemandProperty[],
    public transportPackageAllocations?: ITransportPackageAllocation[],
    public rowId?: string,
    public isSelected?: boolean,
    public thickness?: string,
    public customerNo?: string,
    public orderComment?: string,
    public orderRowComment?: string,
    public pickingInst?: string,
    public demandType?: number,
    public transportPackageQty?: number,
    public isActive?: boolean,
    public createDate?: Date,
    public trimmingLocation?: string,
    public radius?: number,
    public specialFormName?: string,
    public beamIconId?:BeamIconEnum,
    public isSpecialFormBeam?: boolean,
    public siteName?: string,
    public planningType?: number,
    public rawLamellaThickness?: number,
    public planeProfile?: IPlaneProfile
  ) {
    this.SelectedForOptimization = true;
    this.rowId = this.uniqID();
    if (!this.Color) {
      this.Color = 'orange';
    }
    this.QtySumUI = 0;
  }

  private uniqID(): string {
    return `${this.transportPackageId}_${this.BeamOrderRowID}`;
  }

  get OrderText(): string {
    return this.orderID + ':' + this.orderRowID;
  }

  get TemplateName(): string {
    return this.beamMaterialType == null ? '' : this.beamMaterialType.name;
  }

  get HasTemplate(): boolean {
    return this.beamMaterialType !== undefined && this.beamMaterialType !== null;
  }

  get BeamText(): string {
    return `${this.width}x${this.DefaultLamminaPlaneThickness * this.NumberOfLaminas}x${this.length}`;
  }

  get QtyAlloatedOnDemand(): number {
    return this.QtySumUI + this.QtyPlanned - this.QtyReproduce;
  }

  get ProdState(): ProdState {
    const s = ProdState.ON_TIME;
    return s;
  }
}

export interface AddLineParameter {
  beamMaterialLine: BeamMaterialLine;
  demand: Demandline;
  IsPasted: boolean;
  isSpecialForm: boolean;
}

export enum ProdState {
  ON_TIME = 1,
  POSSIBLE_DELAY = 2
}

export enum BeamIconEnum {
  Straight = 0,
  Arched = 1,
  FishBelly = 2,
  GableRoof = 3,
  SpecialForm = 4,
  StraightWithElevation = 5
}
