import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CardItem, IDashTileMember, IGltDashFilterData } from '../models/CardItem.model';
import { Subject, takeUntil } from 'rxjs';

import { CardItemService } from '../services/dashbord-card-item.service';

// import { ngxLoadingAnimationTypes } from 'ngx-spinner';
@Component({
  selector: 'app-tile-content',
  template: `
  <div class="example-inner">
        <div class="k-loading-panel" *ngIf="loading">
          <div class="k-loading-panel-mask"></div>
          <div class="k-loading-panel-wrapper">
            <kendo-loader
              type="infinite-spinner"
              themeColor="primary"
              size="large"
            >
            </kendo-loader>
          </div>
        </div>
        <div class="dynamic-component"><ng-template  #dynamicComponent></ng-template></div>
      </div>
    `,
  styleUrls: ['./dashboard-layout.component.css'],


})
export class TileContentComponent<T> implements AfterViewInit {

  @Input() card: CardItem<T>;

  @Input() update: Subject<T>;

  @ViewChild('dynamicComponent', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef;

  public loading: boolean = false;

  private tielComponent: IDashTileMember<T>

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    public cardService: CardItemService) {

    console.log("app-dashboard-layout-card Const");
  }
  ngAfterViewInit(): void {
    this.loadComponent();
  }
  loadComponent() {

    const componentRef = this.dynamicComponent.createComponent(this.card.component);

    this.tielComponent = componentRef.instance as IDashTileMember<T>;

    this.tielComponent.data = this.card.data;

    this.tielComponent.subscribeUpdate(this.update);

    this.tielComponent.loadingCallback().pipe(takeUntil(this.componentDestroyed$)).subscribe(l => {
      this.loading = l;
    })

  }

  ngOnDestroy(): void {
    console.log('destroyed');
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}




