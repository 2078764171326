import { HttpParams } from '@angular/common/http';
import { IGltDashFilterData } from '@app/modules/dashboard/models/CardItem.model';

export class AnalyticsHelper {

  public static mapHttpParam(data:IGltDashFilterData):HttpParams{
    var h = new HttpParams();

    h = h.append('start', data.start.toISOString());
    h = h.append('end', data.end?.toISOString());

    return h;
  }
}
