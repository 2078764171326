import { Component, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IManualBeamMaterialCreateDeamndForm, IManualBeamMaterialCreateForm } from '@app/core/models/forms/gluelam/manual-beam-material-create-form.model';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';

marker([
  'App.Cancel',
  'Beams.ManualBeamMaterial',
  'App.Add'
]);

const validateForm: ValidatorFn = (control: AbstractControl) => {


  if (control.get('externalId').value === BeamMaterialType.UNKNOWN) {
    return { required : true };
  }

  return null;
};

@Component({
  selector: 'app-manual-beam-material-dialog',
  templateUrl: './manual-beam-material-dialog.component.html',
  styleUrls: ['./manual-beam-material-dialog.component.css']
})
export class ManualBeamMaterialDialogComponent extends DialogContentBase implements OnInit {

  form:FormGroup<IManualBeamMaterialCreateForm> = null;

  demandlines:Demandline[] = [];
  disableMaterialControl: boolean = false;

  constructor(
    private readonly dialogRef: DialogRef,
    public notificationService: AppNotificationService) {
    super(dialogRef);
  }

  ngOnInit(): void {
    console.log(this.demandlines)
  }

  setupForm(demandLine:Demandline[]){
    this.demandlines = demandLine;
    const demands = new FormArray<FormGroup<IManualBeamMaterialCreateDeamndForm>>([]);

    for(let d of this.demandlines){
      demands.push(new FormGroup<IManualBeamMaterialCreateDeamndForm>({
        demandId: new FormControl<number>(d.BeamOrderRowID, [ Validators.required ]),
        qty: new FormControl<number>({ value: 1, disabled: true } , [ Validators.required ]),
      }))
    }

    this.form = new FormGroup<IManualBeamMaterialCreateForm>({
      beamMaterialTypeId: new FormControl<number>(0, [Validators.required]),
      externalId:new FormControl<number>(0, [Validators.required]),
      length: new FormControl<number>({ value: demandLine[0].length, disabled: true } , [Validators.required,]),
      materialId: new FormControl<number>({ value: demandLine[0].materialIndex, disabled: true } , [Validators.required ]),
      numberOfLaminas: new FormControl<number>({ value: demandLine[0].NumberOfLaminas, disabled: true }, [Validators.required ]),
      planingThickness: new FormControl<number>(demandLine[0].DefaultLamminaPlaneThickness, [Validators.required ]),
      noOfLabels: new FormControl<number>(1, [Validators.required ]),
      demands: demands
    }, [validateForm]);

    this.form.get('externalId').valueChanges.subscribe(externalId => {

      switch(externalId) {
        case BeamMaterialType.UNKNOWN:
          this.disableAllControls();
          break;
        case BeamMaterialType.ANGELD_CUT:
          this.disableAllControls();
          this.enableDemandQtyControl();
          this.form.get('length').enable();
          break;
        case BeamMaterialType.WIDTH_SPLIT:
          this.disableAllControls();
          this.enableDemandQtyControl();
          this.form.get('length').enable();
          this.disableMaterialControl = false;
          break;
        case BeamMaterialType.ANGLED_HEIGHT_SPLIT:
        case BeamMaterialType.ANGELD_HEIGHT_SLICE:
          this.disableAllControls();
          this.enableDemandQtyControl();
          this.form.get('length').enable();
          this.form.get('numberOfLaminas').enable();
          break;
      }
    });
  }

  selectedBeamMaterialType(bm:IBeamMaterialType) {
    this.form.get('externalId').setValue(bm.externalId);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addManualBeamMaterial() {
    this.dialogRef.close(this.form);
  }

  getDemandFormArray():FormArray{
    return this.form.controls["demands"] as FormArray
  }

  getDemandFormControl(index:number, name: string):FormControl{
    const a = this.getDemandFormArray();

    const fg = a.at(index) as FormGroup;

    return fg.controls[name] as FormControl;
  }

  private disableAllControls() {
    this.form.get('length').disable();
    this.disableMaterialControl = true;
    this.form.get('numberOfLaminas').disable();
    this.enableDemandQtyControl(false);
  }

  private enableDemandQtyControl(isEnable: boolean = true) {
    var demandFormArray = this.getDemandFormArray();
    for (let index = 0; index < demandFormArray.length; index++) {
      const fg = demandFormArray.at(index) as FormGroup;
      const ctrl = fg.controls['qty'] as FormControl;
      isEnable ? ctrl.enable() : ctrl.disable();
    }
  }
}
